import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from 'Components/Button'
import { DatePicker } from 'Components/DatePicker'
import { FormInputFoodAmount } from 'Components/FormInputFoodAmount'
import { FormInputSelect } from 'Components/FormInputSelect'
import { Mode } from 'Components/JournalCardSearchBase'
import {
  SelectItemKeyExtractor,
  SelectItemRenderer,
  SelectItemType,
  defaultKeyExtractor
} from 'Components/common/SelectPickerProps'
import { Amount, Food, MealCategory, QuantifiedEatable, Recipe, UnitId, allMealCategories } from 'Models'
import { setJournalDateAction } from 'ReduxStore/journalDay'
import { postOrPatchJournalEatableRecordAction } from 'ReduxStore/journalEatableRecords'
import { Formatter, Utils } from 'Utils'

interface Props {
  recipe?: Recipe
  food?: Food
  quantifiedEatable?: QuantifiedEatable
}

export const AddEatableToJournal: FC<Props> = ({ recipe, food, quantifiedEatable }) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const [mode, setMode] = useState<Mode>('normal')

  const eatable = recipe ? { recipe } : { food }

  const { quantity, unit } = quantifiedEatable || {}

  const [amount, setAmount] = useState<Amount>(
    quantity && unit ? { quantity, unit } : Utils.defaultEatableAmount(eatable)
  )

  const [date, setDate] = useState<Date>(new Date())

  const [category, setCategory] = useState<MealCategory>(() => Utils.currentMealCategory())

  const availableUnits = Utils.availableUnits(eatable)

  const handleCategoryChange = (category: SelectItemType): void => {
    setCategory(category as MealCategory)
  }

  const handleSave = async (): Promise<void> => {
    setMode('submitted')
    const result = await dispatch(
      postOrPatchJournalEatableRecordAction(
        { ...eatable, quantity: amount.quantity, unit: amount.unit },
        category,
        date,
        recipe ? 'recipe-details' : food ? 'food-details' : ''
      )
    )

    if ('error' in result) {
      return
    }

    dispatch(setJournalDateAction(date))
    history.push('/tagebuch')
  }

  return (
    <>
      <div className="standardcard-icons">
        {mode === 'adding' && (
          <>
            <div className="col-12 mb-2">
              <h2>{recipe?.name ?? food?.name ?? 'Namenloses Nahrungsmittel'}</h2>
            </div>

            <div className="col-4 mb-2">
              <Button name="saveJournalCard" theme="small" onClick={handleSave}>
                Speichern
              </Button>
            </div>
          </>
        )}

        {mode === 'normal' && (
          <div className="col-12 px-3">
            <Button name="addJournalCard" theme="small" onClick={() => setMode('adding')}>
              Zum Tagebuch hinzufügen
            </Button>
          </div>
        )}

        {mode === 'submitted' && null}
      </div>

      {mode === 'adding' && (
        <div className="standardcard-options pt-2 px-0">
          <div className="mx-0">
            <FormInputFoodAmount
              label=" "
              amount={amount}
              availableUnits={availableUnits}
              onAmountChange={setAmount}
              onChangeDelay={0}
            />
          </div>

          <div>
            <DatePicker label=" " value={date} onChange={setDate} />
          </div>

          <div>
            <FormInputSelect
              label=" "
              name="mealCategory"
              items={allMealCategories}
              selectedKey={category ? defaultKeyExtractor(category) : undefined}
              labelExtractor={Formatter.formatMealCategory as SelectItemRenderer<SelectItemType, string>}
              keyExtractor={defaultKeyExtractor as SelectItemKeyExtractor<SelectItemType>}
              onChange={handleCategoryChange}
            />
          </div>
        </div>
      )}
    </>
  )
}
