import Markdown from 'markdown-to-jsx'
import React, { FC, memo, useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

import DownloadBanner from 'Components/DownloadBanner/DownloadBanner'
import { RoutePathTemplateFunction, buildCdnImageUrl, onLogin, onLogout, routes } from 'Config'
import { KeyCloakUser } from 'Models'
import { ZIndexPriority } from 'Utils/zIndexPriority'

type Props = {
  isActiveCustomer: boolean
  firstName: string
  user: KeyCloakUser
  bannerMessage?: string
  isLoading?: boolean
  isAnamnesis?: boolean
  showResetUserButton?: boolean
  resetUser?: () => void
}

const PageHeader: FC<Props> = ({
  isActiveCustomer,
  firstName = '',
  user: { authenticated, attributes, accountUrl = '' },
  isAnamnesis = false,
  isLoading = false,
  showResetUserButton = false,
  bannerMessage,
  resetUser
}: Props) => {
  const currentPath = useLocation().pathname

  const [displayOffCanvas, setDisplayOffCanvas] = useState<boolean>(false)
  const [pageScrolled, setPageScrolled] = useState<boolean>(false)

  useEffect(() => {
    const watchScroll = (): void => {
      window.scrollY >= 20 ? setPageScrolled(true) : setPageScrolled(false)
    }

    window.addEventListener('scroll', watchScroll)

    return () => window.removeEventListener('scroll', watchScroll)
  }, [])

  const classNameForMainNav = (routes: Record<string, string | RoutePathTemplateFunction>): string =>
    Object.values(routes)
      .map((r) => (typeof r === 'string' ? r : r()))
      .includes(currentPath)
      ? 'is-active'
      : ''

  return (
    <>
      <div style={{ zIndex: ZIndexPriority.MEDIUM }} className={`header ${pageScrolled ? 'is-scrolled' : ''}`}>
        {bannerMessage && (
          <div className="header-banner">
            <Markdown options={{ forceBlock: true }}>{bannerMessage}</Markdown>
          </div>
        )}
        <DownloadBanner />
        <div className="inner">
          <Link to="/">
            <img src={buildCdnImageUrl('logo-white.svg')} alt="Logo" />
          </Link>
          {!isLoading && (
            <nav>
              <ul>
                {authenticated ? (
                  isAnamnesis ? (
                    <>
                      <li>
                        <NavLink to={routes.register.anamnesis} activeClassName="is-active">
                          Anamnese
                        </NavLink>
                      </li>
                      <li>
                        <a onClick={onLogout}>Abmelden</a>
                      </li>
                      {showResetUserButton && (
                        <li>
                          <a onClick={resetUser}>
                            <span className="reset-user-button">Reset User</span>
                          </a>
                        </li>
                      )}
                    </>
                  ) : (
                    <>
                      <li>
                        <NavLink to={routes.journal} activeClassName="is-active">
                          Tagebuch
                        </NavLink>
                      </li>
                      <li>
                        <div className={classNameForMainNav(routes.analytics)}>
                          <div className="has-subnav">Analyse</div>
                        </div>
                        <ul className="nav-subnav">
                          <li>
                            <NavLink to={routes.analytics.nutritions} activeClassName="is-active">
                              Nährwerte
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={routes.analytics.mass} activeClassName="is-active">
                              Masse
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className={classNameForMainNav(routes.inspiration)}>
                          <div className="has-subnav">Inspiration</div>
                        </div>
                        <ul className="nav-subnav">
                          <li>
                            <NavLink to={routes.inspiration.recipes} activeClassName="is-active">
                              Rezepte
                            </NavLink>
                          </li>
                          <li>
                            <a href="https://ebalance.ch/magazin" target="_blank" rel="noopener noreferrer">
                              Magazin
                            </a>
                          </li>
                          <li>
                            <a href="https://ebalance.ch/botschafter" target="_blank" rel="noopener noreferrer">
                              Botschafter
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className={classNameForMainNav(routes.myEatables)}>
                          <div className="has-subnav">Mein Essen</div>
                        </div>
                        <ul className="nav-subnav">
                          <li>
                            <NavLink to={routes.myEatables.index} activeClassName="is-active">
                              Mein Essen
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={routes.myEatables.favoritesOverview} activeClassName="is-active">
                              Favoriten
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={routes.myEatables.myFoodCreate} activeClassName="is-active">
                              Nahrungsmittel erstellen
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={routes.myEatables.myRecipeCreate} activeClassName="is-active">
                              Rezept erstellen
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className={classNameForMainNav(routes.profile)}>
                          <div className="has-subnav">Hallo, {firstName}!</div>
                          <div className="nav-photo">
                            {attributes.imageUrl && <img src={attributes.imageUrl} alt="user" />}
                          </div>
                        </div>
                        <ul className="nav-subnav">
                          <li>
                            <NavLink to={routes.profile.profile} activeClassName="is-active">
                              Meine Kundendaten
                            </NavLink>
                          </li>
                          {!isActiveCustomer && (
                            <li>
                              <NavLink to={routes.register.plan} activeClassName="is-active">
                                Mitgliedschaften
                              </NavLink>
                            </li>
                          )}

                          <li>
                            <NavLink to={routes.profile.program} activeClassName="is-active">
                              Mein Programm
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={routes.imageUpload} activeClassName="is-active">
                              Mein Bild
                            </NavLink>
                          </li>
                          <li>
                            <a href={accountUrl}>E-Mail</a>
                          </li>
                          <li>
                            <a href={accountUrl.replace('/account', '/account/password')}>Passwort</a>
                          </li>
                          <li>
                            <NavLink to={routes.help} activeClassName="is-active">
                              Hilfe
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={routes.recruit} activeClassName="is-active">
                              Mitglieder werben
                            </NavLink>
                          </li>
                          <li>
                            <a onClick={onLogout}>Abmelden</a>
                          </li>
                          <li>
                            {showResetUserButton && (
                              <a onClick={resetUser}>
                                <span className="reset-user-button">Reset User</span>
                              </a>
                            )}
                          </li>
                        </ul>
                      </li>
                    </>
                  )
                ) : (
                  <li>
                    <a onClick={onLogin}>Anmelden</a>
                  </li>
                )}
                <li
                  className={`offcanvas-toggle ${displayOffCanvas ? 'is-open' : ''}`}
                  onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                >
                  <svg viewBox="0 0 100 100">
                    <path d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
                    <path d="m 70,50 h -40" />
                    <path d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
                  </svg>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
      <div className={`offcanvas ${displayOffCanvas ? 'is-open' : ''}`}>
        <div className="inner">
          <nav>
            <ul>
              {authenticated ? (
                isAnamnesis ? (
                  <>
                    <li>
                      <NavLink
                        to={routes.register.anamnesis}
                        activeClassName="is-active"
                        onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                      >
                        Anamnese
                      </NavLink>
                    </li>
                    <li>
                      <a onClick={onLogout}>Abmelden</a>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <NavLink
                        to={routes.journal}
                        activeClassName="is-active"
                        onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                      >
                        Tagebuch
                      </NavLink>
                    </li>
                    <li>
                      <div className={classNameForMainNav(routes.analytics)}>
                        <div className="has-subnav">Analyse</div>
                      </div>
                      <ul className="nav-subnav">
                        <li>
                          <NavLink
                            to={routes.analytics.nutritions}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Nährwerte
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.analytics.mass}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Masse
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className={classNameForMainNav(routes.inspiration)}>
                        <div className="has-subnav">Inspiration</div>
                      </div>
                      <ul className="nav-subnav">
                        <li>
                          <NavLink
                            to={routes.inspiration.recipes}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Rezepte
                          </NavLink>
                        </li>
                        <li>
                          <a href="https://ebalance.ch/magazin" target="_blank" rel="noopener noreferrer">
                            Magazin
                          </a>
                        </li>
                        <li>
                          <a href="https://ebalance.ch/botschafter" target="_blank" rel="noopener noreferrer">
                            Botschafter
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className={classNameForMainNav(routes.myEatables)}>
                        <div className="has-subnav">Mein Essen</div>
                      </div>
                      <ul className="nav-subnav">
                        <li>
                          <NavLink
                            to={routes.myEatables.index}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Mein Essen
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.myEatables.favoritesOverview}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Favoriten
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.myEatables.myFoodCreate}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Nahrungsmittel erstellen
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.myEatables.myRecipeCreate}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Rezept erstellen
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to={routes.help}
                        activeClassName="is-active"
                        onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                      >
                        Hilfe
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={routes.recruit}
                        activeClassName="is-active"
                        onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                      >
                        Mitglieder werben
                      </NavLink>
                    </li>
                    <li>
                      <div className={classNameForMainNav(routes.profile)}>
                        <div className="has-subnav">Hallo, {firstName}!</div>
                      </div>
                      <ul className="nav-subnav">
                        <li>
                          <NavLink
                            to={routes.profile.profile}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Meine Kundendaten
                          </NavLink>
                        </li>
                        {!isActiveCustomer && (
                          <li>
                            <NavLink
                              to={routes.register.plan}
                              activeClassName="is-active"
                              onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                            >
                              Mitgliedschaften
                            </NavLink>
                          </li>
                        )}

                        <li>
                          <NavLink
                            to={routes.profile.program}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Mein Programm
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.imageUpload}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Mein Bild
                          </NavLink>
                        </li>
                        <li>
                          <a href={accountUrl}>E-Mail</a>
                        </li>
                        <li>
                          <a href={accountUrl.replace('/account', '/account/password')}>Passwort</a>
                        </li>
                        <li>
                          <NavLink
                            to={routes.help}
                            activeClassName="is-active"
                            onClick={() => setDisplayOffCanvas(!displayOffCanvas)}
                          >
                            Hilfe
                          </NavLink>
                        </li>
                        <li>
                          <a onClick={onLogout}>Abmelden</a>
                        </li>
                      </ul>
                    </li>
                  </>
                )
              ) : (
                <li>
                  <a onClick={onLogin}>Anmelden</a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}

export default memo(PageHeader)
