import React, { FC, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import DetailCardHeader from '../common/DetailCardHeader/DetailCardHeader'

import DetailCardRecipeContent from './DetailCardRecipeContent'

import { QuantifiedRecipe, Recipe } from 'Models'

interface Props {
  recipe: Recipe
  quantifiedRecipe?: QuantifiedRecipe
  isFavorite?: boolean
  onFavoriteChange?: (value: boolean) => void
  onClose?: () => void
}

export const DetailCardRecipe: FC<Props> = ({
  recipe,
  quantifiedRecipe,
  isFavorite,
  onClose,
  onFavoriteChange
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const handlePrint = useReactToPrint({
    contentRef,
    pageStyle: `
      @page {
        size: auto;
        margin:  0;
        padding-top: 1.2cm;
        padding-bottom: 1.2cm;
      }
      @page :first {
        padding-top: 0;
      }
      body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }

    `
  })

  return (
    <div className="detailcard" ref={contentRef}>
      <DetailCardHeader
        energy={(recipe.nutritionsPerPortion || {}).energy || 0}
        imageUrl={recipe.imageUrl}
        isFavorite={isFavorite}
        onClose={onClose}
        onFavoriteChange={onFavoriteChange}
        source={recipe.source}
        portionAmount={recipe.portionAmount}
        extension="pro Portion"
      />
      <DetailCardRecipeContent recipe={recipe} quantifiedRecipe={quantifiedRecipe} handlePrint={handlePrint} />
    </div>
  )
}
