import React, { FC, ReactElement, useMemo } from 'react'

import TabSwitcher, { TabSwitcherProps } from '../../Components/TabSwitcher/TabSwitcher'
import FoodModal from '../JournalPage/components/Modals/FoodModal/FoodModal'
import RecipeModal from '../JournalPage/components/Modals/RecipeModal/RecipeModal'

import FoodContainer from './FoodCardContainer'
import RecipeCardContainer from './RecipeCardContainer'

import { InfoPlaceholder, PageLayout, SectionList, getSectionListSortedByNormalizedName } from 'Components'
import { addNormalizedNameProp } from 'Components/SectionList/sortItemList'
import { CoreBackendRecipe, Food, QuantifiedFood, Recipe } from 'Models'
import { Utils } from 'Utils'

type OnCardClickCallback = (id: string) => void

type MyEatablesPageProps = {
  // foods
  userFoods: Food[]
  isFoodsLoading: boolean
  didLoadUserFoods: boolean
  modalFoodId?: string
  onFoodCardClick: OnCardClickCallback
  onFoodModalClose: () => void
  // recipes
  userRecipes: CoreBackendRecipe[]
  isRecipesLoading: boolean
  didLoadUserRecipes: boolean
  modalRecipeId?: string
  onRecipeCardClick: OnCardClickCallback
  onRecipeModalClose: () => void
}

const MyEatablesPage: FC<MyEatablesPageProps> = ({
  // foods
  userFoods,
  isFoodsLoading,
  didLoadUserFoods,
  modalFoodId,
  onFoodCardClick,
  onFoodModalClose,

  // recipes
  userRecipes,
  isRecipesLoading,
  didLoadUserRecipes,
  modalRecipeId,
  onRecipeCardClick,
  onRecipeModalClose
}: MyEatablesPageProps) => {
  const isInitialLoadingFoods = isFoodsLoading && !didLoadUserFoods
  const isInitialLoadingRecipes = isRecipesLoading && !didLoadUserRecipes

  const selectedFood: QuantifiedFood | undefined = useMemo(() => {
    const food = userFoods.find(({ id }) => id === modalFoodId)
    if (!food) return undefined
    const { quantity, unit } = Utils.defaultEatableAmountForFood(food)
    return {
      food,
      quantity,
      unit
    }
  }, [userFoods, modalFoodId])
  const selectedRecipe: QuantifiedFood | undefined = useMemo(() => {
    const recipe = userRecipes.find(({ id }) => id === modalRecipeId)
    if (!recipe) return undefined
    return {
      recipe,
      quantity: 1,
      unit: 'PORTION'
    }
  }, [userRecipes, modalRecipeId])

  const renderFoods = (): ReactElement | ReactElement[] => {
    if (isInitialLoadingFoods) {
      return (
        <div className="text-center">
          <p>Nahrungsmittel werden geladen …</p>
        </div>
      )
    }
    if (userFoods.length === 0) {
      return <InfoPlaceholder text="Sie haben noch keine eigenen Nahrungsmittel erstellt." />
    }

    return (
      <SectionList<Food>
        sectionList={getSectionListSortedByNormalizedName(
          userFoods.map((food) => addNormalizedNameProp(food, food.name))
        )}
        renderCard={(food: Food) => (
          <FoodContainer key={food.id} food={food} onClick={() => onFoodCardClick(food.id)} />
        )}
      />
    )
  }

  const renderRecipes = (): ReactElement | ReactElement[] => {
    if (isInitialLoadingRecipes) {
      return (
        <div className="text-center">
          <p>Rezepte werden geladen …</p>
        </div>
      )
    }
    if (userRecipes.length === 0) {
      return <InfoPlaceholder text="Sie haben noch keine eigenen Rezepte erstellt." />
    }

    return (
      <SectionList<Recipe>
        sectionList={getSectionListSortedByNormalizedName(
          userRecipes.map((recipe) => addNormalizedNameProp(recipe, recipe.name))
        )}
        renderCard={(recipe: Recipe) => (
          <RecipeCardContainer key={recipe.id} recipe={recipe} onClick={() => onRecipeCardClick(recipe.id)} />
        )}
      />
    )
  }

  const tabs: TabSwitcherProps = {
    tabs: [
      {
        name: 'foods',
        title: 'Meine Nahrungsmittel',
        renderContent: renderFoods()
      },
      {
        name: 'recipes',
        title: 'Meine Rezepte',
        renderContent: renderRecipes()
      }
    ]
  }

  return (
    <PageLayout theme="smallcircle">
      <FoodModal quantifiedFood={selectedFood} onClose={onFoodModalClose} />
      <RecipeModal quantifiedRecipe={selectedRecipe} onClose={onRecipeModalClose} />
      <div className="container max-700">
        <div className="col-12">
          <h1>Mein Essen</h1>
          <p>
            Hier sehen Sie eine Übersicht Ihrer selbst erstellten Nahrungsmittel und Rezepte. Sie können diese auch über
            die Suche dem Tagebuch hinzufügen.
          </p>
        </div>
        <TabSwitcher tabs={tabs.tabs} />
      </div>
    </PageLayout>
  )
}

export default MyEatablesPage
