import React, { useEffect, useMemo, useState } from 'react'

import { IconColor, IconName } from 'Components/Icon'
import { IconButton } from 'Components/IconButton'
import getMobileOs, { MobileOs } from 'Utils/helpers/getMobileOs'

const DownloadBanner: React.FC = () => {
  const [isBannerHidden, setIsBannerHidden] = useState(false)

  const mobileOs: MobileOs = useMemo(() => getMobileOs(), [])

  useEffect(() => {
    if (localStorage.getItem('appDownloadBannerHidden') === 'true') {
      setIsBannerHidden(true)
    }
  }, [])

  if (mobileOs !== 'ios' && mobileOs !== 'android') {
    return null
  }

  if (isBannerHidden) {
    return null
  }

  const handleDismiss = (): void => {
    setIsBannerHidden(true)
    localStorage.setItem('appDownloadBannerHidden', 'true')
  }

  const storeName = { android: 'Google Play Store', ios: 'App Store' }

  const storeUrl = {
    android: 'https://play.google.com/store/apps/details?id=ch.ebalance.app',
    ios: 'https://apps.apple.com/ch/app/ebalance/id6444804624'
  }

  return (
    <>
      <div className="bg-white p-2" style={{ display: 'flex', gap: '1rem' }}>
        <img src="apple-touch-icon.png" width={56} height={56} />

        <div>Jezt die eBalance-App im {storeName[mobileOs]} herunterladen!</div>

        <IconButton name={IconName.close} color={IconColor.GRAY_1} onClick={handleDismiss} />
      </div>

      <p className="text-center bg-white pb-2">
        <a href={storeUrl[mobileOs]}>Download</a>
      </p>
    </>
  )
}

export default DownloadBanner
