import React, { FC, useEffect } from 'react'
import { RefinementListProvided } from 'react-instantsearch-core'
import { connectRange, connectRefinementList } from 'react-instantsearch-dom'

import RecipeCategoryTitles from './RecipeCategoryTitles'
import styles from './RecipesInspirationPage.module.css'
import { RangeSliderPropType, RangeSliderValuesType, RefineNewPropType } from './RecipesInspirationPageTypes'

import { FormInputRange } from 'Components'
import { coreBackendService } from 'Services'
import { ToggleModalProps } from 'hooks/useToggleModal'

export const RecipesInspirationFilter: FC<{ modalProps: ToggleModalProps }> = ({ modalProps }) => {
  const { ref, isModalOpen, closeModal } = modalProps

  const CoreFilterContent = (): React.ReactElement => (
    <>
      <CustomRefineNew attribute="createdTimestamp" />
      <div className="filter-sidebar-title mt-2 mb-1">Ernährungsvariante</div>
      <CustomRefinementList attribute="diet" operator="or" />
      <CustomRangeSlider
        attribute={'nutritionsPerPortion.energy'}
        rangeSliderValues={getEnergyPerPortionSliderValues()}
      />
      <CustomRangeSlider attribute={'preparationTime'} rangeSliderValues={getPreparationTimeSliderValues()} />
      <CustomRefinementButtons attribute="recipeCategoryNames" operator="or" limit={1000} />
    </>
  )

  const MobileFilter = (): React.ReactElement => (
    <div ref={ref} className={`offcanvas ${isModalOpen ? 'is-open' : ''}`}>
      <div className="mt-4 p-2 pt-4">
        <CoreFilterContent />

        <div className="filter-mobile-trigger filter-toggle" onClick={closeModal}>
          <div className="icon icon-filter-gray-1"></div>
          <span>Filter schliessen</span>
        </div>
      </div>
    </div>
  )

  const DesktopFilter = (): React.ReactElement => (
    <div className="col-12 col-lg-3 filter-sidebar">
      <h2 className="text-gray-2 mt-1">Erweiterte Suche</h2>
      <p className="text-small text-gray-2 mt-2 mb-2">Hier können Sie Ihre Suche eingrenzen</p>
      <CoreFilterContent />
    </div>
  )

  return (
    <>
      <MobileFilter />
      <DesktopFilter />
    </>
  )
}

const RefineNew: FC<RefineNewPropType> = ({ currentRefinement, refine }) => {
  const MAXIMUM_AGE_OF_NEW_RECIPES_IN_MONTH = 2

  const now = new Date()

  const minTimestamp = Math.floor(
    new Date(now.setMonth(now.getMonth() - MAXIMUM_AGE_OF_NEW_RECIPES_IN_MONTH)).getTime() / 1000
  )

  const handleCheckboxChange = (): void => {
    !currentRefinement.min ? refine({ min: minTimestamp }) : refine({})
  }

  return (
    <div>
      <label className="checkbox">
        <input type="checkbox" checked={!!currentRefinement.min} onChange={handleCheckboxChange} />
        <span className="text-gray-2 label">Neue Rezepte </span>
      </label>
    </div>
  )
}

const CustomRefineNew = connectRange(RefineNew)

const RangeSlider: FC<RangeSliderPropType> = ({ max, currentRefinement, rangeSliderValues, refine }) => {
  const { label, defaultValue, maximumValue, minimumValue, inputStep } = rangeSliderValues
  const onChange = (value: number): void => {
    refine({ min: currentRefinement.min, max: value >= max ? max : value })
  }

  return (
    <>
      <div className={`filter-sidebar-title mb-1`}>
        <span>{label}</span>
      </div>
      <div className="mr-2">
        <FormInputRange
          defaultValue={defaultValue}
          maximumValue={maximumValue}
          minimumValue={minimumValue}
          onChange={onChange}
          renderValue={(value) => (value === maximumValue ? `${value}+` : `${value}`)}
          inputStep={inputStep}
        />
      </div>
    </>
  )
}

const getPreparationTimeSliderValues = (): RangeSliderValuesType => {
  return {
    label: 'max. Zubereitungszeit',
    defaultValue: 120,
    maximumValue: 120,
    minimumValue: 5,
    inputStep: '5'
  }
}

const getEnergyPerPortionSliderValues = (): RangeSliderValuesType => {
  return {
    label: 'max. Kalorien pro Portion',
    defaultValue: 600,
    maximumValue: 600,
    minimumValue: 50,
    inputStep: '50'
  }
}

const DisplayNoRefinementsText = (): React.ReactElement => {
  return <div className="text-small text-gray-2 mb-5">Kein Filter möglich</div>
}

const CustomRangeSlider = connectRange(RangeSlider)

const RefinementList: FC<RefinementListProvided> = ({ items, refine }) => {
  items.sort((a, b) => a.label.localeCompare(b.label))

  const displayRefinements = (): React.ReactElement => {
    return (
      <>
        <ul className="mb-5">
          {items.map((item) => (
            <li key={item.label}>
              <label key={item.label} className="checkbox">
                <input
                  value={item.label}
                  type="checkbox"
                  checked={item.isRefined}
                  onChange={() => refine(item.value)}
                />
                <span className="text-gray-2 label">
                  {item.label} ({item.count})
                </span>
              </label>
            </li>
          ))}
        </ul>
      </>
    )
  }
  return (
    <div className={styles.dietFilter}>{items.length === 0 ? DisplayNoRefinementsText() : displayRefinements()}</div>
  )
}

const RefinementButtons: FC<RefinementListProvided> = ({ items, refine }) => {
  const [publishedRecipeCategoryNames, setPublishedRecipeCategoryNames] = React.useState<string[]>([])

  useEffect(() => {
    const fetchRecipeCategories = async (): Promise<void> => {
      const RecipeCategories = await coreBackendService.fetchPublishedRecipeCategories()
      setPublishedRecipeCategoryNames(RecipeCategories.map((category) => category.name))
    }
    fetchRecipeCategories()
  }, [])

  const publishedRecipeCategoriesWithTitles = items
    .filter((item) => publishedRecipeCategoryNames.includes(item.label))
    .map((item) => ({
      ...item,
      title: RecipeCategoryTitles[item.label as keyof typeof RecipeCategoryTitles]
    }))
    .sort((a, b) => a.title.localeCompare(b.title))

  const displayRefinements = (): React.ReactElement => {
    return (
      <div className="mb-5 h-16">
        {publishedRecipeCategoriesWithTitles.map((category) => (
          <input
            key={category.label}
            value={category.title}
            type="button"
            onClick={() => {
              refine(category.value)
            }}
            className={`mb-1 mr-1 px-1 py-0.4 ${
              category.isRefined ? 'recipe-category-button-active' : 'recipe-category-button'
            }`}
          />
        ))}
      </div>
    )
  }

  return (
    <div>{publishedRecipeCategoriesWithTitles.length === 0 ? DisplayNoRefinementsText() : displayRefinements()}</div>
  )
}

const CustomRefinementList = connectRefinementList(RefinementList)

const CustomRefinementButtons = connectRefinementList(RefinementButtons)
