import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Icon, IconColor, IconName } from '../Icon'

import { buildCdnImageUrl, routes } from 'Config'

type Props = {
  userAuthenticated: boolean
}

export const PageFooter: FC<Props> = ({ userAuthenticated }: Props) => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          {userAuthenticated && (
            <div className="col-12 col-lg-2">
              <h3 className="mb-2">Übersicht</h3>
              <ul className="text-small">
                <li>
                  <Link to={routes.journal}>Tagebuch</Link>
                </li>
                <li>
                  <Link to={routes.analytics.nutritions}>Nährwerte</Link>
                </li>
                <li>
                  <Link to={routes.analytics.mass}>Masse</Link>
                </li>
              </ul>
            </div>
          )}
          <div className={`col-12 col-lg-2`}>
            <h3 className="mb-2">Über uns</h3>
            <ul className="text-small">
              <li>
                <Link to={routes.contact}>Kontakt</Link>
              </li>
              <li>
                <Link to={routes.help}>Hilfe</Link>
              </li>
              <li>
                <a href="https://ebalance.ch/agb" target="_blank" rel="noopener noreferrer">
                  AGB / Datenschutz
                </a>
              </li>
              <li>
                <a href="https://ebalance.ch/impressum" target="_blank" rel="noopener noreferrer">
                  Impressum
                </a>
              </li>
            </ul>
          </div>
          <div className={`col-12 col-lg`}>
            <h3 className="mb-2">Inspiration</h3>
            <ul className="text-small">
              <li>
                <Link to={routes.inspiration.recipes}>Rezepte</Link>
              </li>
              <li>
                <a href="https://ebalance.ch/magazin" target="_blank" rel="noopener noreferrer">
                  Magazin
                </a>
              </li>
              <li>
                <a href="https://ebalance.ch/alle-botschafter" target="_blank" rel="noopener noreferrer">
                  Botschafter
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-auto">
            <div className="d-flex justify-content-center mb-3">
              <a href="https://www.instagram.com/ebalance.ch/" target="_blank" rel="noopener noreferrer">
                <Icon name={IconName.socialinstagram} color={IconColor.GRAY_2} />
              </a>
              <a href="https://www.facebook.com/eBalance.ch" target="_blank" rel="noopener noreferrer" className="mx-3">
                <Icon name={IconName.socialfacebook} color={IconColor.GRAY_2} />
              </a>
              <a href="https://www.youtube.com/user/eBalance123" target="_blank" rel="noopener noreferrer">
                <Icon name={IconName.socialyoutube} color={IconColor.GRAY_2} />
              </a>
            </div>

            <div className="d-flex justify-content-center">
              <img src={buildCdnImageUrl('logo-gray.svg')} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
