import React, { FC, useState } from 'react'
import { ConnectedProps, connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useManageEatable } from './useManageEatable'

import { DatePicker, FormInputFoodAmount, FormInputSelect, Mode } from 'Components'
import { CardFood } from 'Components/CardFood/CardFood'
import { deleteButton, editButton, favoriteButton } from 'Components/IconButton/iconButtons'
import {
  SelectItemKeyExtractor,
  SelectItemRenderer,
  SelectItemType,
  defaultKeyExtractor
} from 'Components/common/SelectPickerProps'
import { routes } from 'Config'
import { Amount, FavoritesMapping, Food, MealCategory, allMealCategories } from 'Models'
import FavoriteCardButtons from 'Pages/Favorites/FavoriteCardButtons'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAddOrRemoveFavorite } from 'ReduxStore/favorites/useAddOrRemoveFavorite'
import { useAppSelector } from 'ReduxStore/hooks'
import { setJournalDateAction } from 'ReduxStore/journalDay'
import { postOrPatchJournalEatableRecordAction } from 'ReduxStore/journalEatableRecords'
import { hideUserFood } from 'ReduxStore/userFoods'
import { Formatter, Utils } from 'Utils'

const mapDispatchToProps = {
  hideItem: hideUserFood
}

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type FoodCardContainerProps = PropsFromRedux & {
  food: Food
  onClick: () => void
}

const FoodCardContainer: FC<FoodCardContainerProps> = ({ food, onClick, hideItem }: FoodCardContainerProps) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const { id: itemId } = food

  const favoritesFood = useAppSelector(favoriteSelector(FavoritesMapping.FOODS, food.id))

  const { onFavoriteChange } = useAddOrRemoveFavorite()

  const [mode, setMode] = useState<Mode>('normal')

  const [toggleChildrenIsOn, setToggleChildrenIsOn] = useState(false)

  const [amount, setAmount] = useState<Amount>({ quantity: 1, unit: food.defaultUnit ?? 'PORTION' })

  const availableUnits = Utils.availableUnits({ food })

  const [date, setDate] = useState<Date>(new Date())

  const [category, setCategory] = useState<MealCategory>(() => Utils.currentMealCategory())

  const handleCategoryChange = (category: SelectItemType): void => {
    setCategory(category as MealCategory)
  }

  const handleSave = async (): Promise<void> => {
    setMode('normal')

    const result = await dispatch(
      postOrPatchJournalEatableRecordAction(
        { food, quantity: amount.quantity, unit: amount.unit },
        category,
        date,
        'user-eatables-overview'
      )
    )

    if ('error' in result) {
      return
    }

    dispatch(setJournalDateAction(date))

    history.push('/tagebuch')
  }

  const { handleOnEdit, handleOnDelete } = useManageEatable({
    itemId,
    editRoute: routes.myEatables.myFoodEdit,
    hideItem
  })

  const handleFavorite = (): void => {
    const { quantity, unit } = Utils.defaultEatableAmountForFood(food)

    onFavoriteChange(favoritesFood, {
      quantity,
      unit,
      foodId: food.id
    })
  }

  return (
    <CardFood
      food={food}
      onClick={() => {
        setToggleChildrenIsOn(false)
        setMode('normal')
        onClick()
      }}
      cardButtons={[
        <FavoriteCardButtons
          handleSave={handleSave}
          mode={mode}
          setMode={setMode}
          setToggleChildrenIsOn={setToggleChildrenIsOn}
          key={food.name}
        />
      ]}
      toggleChildrenIsOn={toggleChildrenIsOn}
      iconButtons={[
        editButton(handleOnEdit),
        favoriteButton(handleFavorite, !!favoritesFood),
        deleteButton(handleOnDelete)
      ]}
    >
      <div className="standardcard-options">
        <div>
          <FormInputFoodAmount
            label=" "
            amount={amount}
            availableUnits={availableUnits}
            onAmountChange={setAmount}
            onChangeDelay={0}
          />
        </div>

        <div>
          <DatePicker label=" " value={date} onChange={setDate} />
        </div>

        <div>
          <FormInputSelect
            label=" "
            name="mealCategory"
            items={allMealCategories}
            selectedKey={category ? defaultKeyExtractor(category) : undefined}
            labelExtractor={Formatter.formatMealCategory as SelectItemRenderer<SelectItemType, string>}
            keyExtractor={defaultKeyExtractor as SelectItemKeyExtractor<SelectItemType>}
            onChange={handleCategoryChange}
          />
        </div>
      </div>
    </CardFood>
  )
}

export default connector(FoodCardContainer)
