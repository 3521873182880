export type MobileOs = 'windows-phone' | 'ios' | 'android' | 'none'

const getMobileOs = (): MobileOs => {
  const userAgent = navigator.userAgent

  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone'
  }

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios'
  }

  return 'none'
}

export default getMobileOs
