import React from 'react'
import Modal from 'react-modal'

import { DetailCardFood } from 'Components'
import { FavoritesMapping, QuantifiedFood } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAddOrRemoveFavorite } from 'ReduxStore/favorites/useAddOrRemoveFavorite'
import { useAppSelector } from 'ReduxStore/hooks'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

type Props = {
  quantifiedFood?: QuantifiedFood
  onClose: () => void
}

const FoodModal: React.FC<Props> = ({ quantifiedFood, onClose }: Props) => {
  const favorite = useAppSelector(favoriteSelector(FavoritesMapping.FOODS, quantifiedFood?.food?.id))
  const { onFavoriteChange } = useAddOrRemoveFavorite()

  const handleOnFavoriteChange = (): void => {
    onFavoriteChange(favorite, {
      quantity: quantifiedFood!.quantity!,
      unit: quantifiedFood!.unit!,
      foodId: quantifiedFood!.food!.id
    })
  }

  return (
    <Modal
      style={modalZIndexPriority}
      isOpen={!!(quantifiedFood && quantifiedFood.food)}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <div className="modal is-medium">
        {quantifiedFood && quantifiedFood.food ? (
          <DetailCardFood
            food={quantifiedFood.food}
            quantifiedFood={quantifiedFood}
            isFavorite={!!favorite}
            onFavoriteChange={handleOnFavoriteChange}
            onClose={onClose}
          />
        ) : (
          <div>Modal Placeholder</div>
        )}
      </div>
    </Modal>
  )
}

export default FoodModal
