import React, { FC } from 'react'

import DetailCardHeader from '../common/DetailCardHeader/DetailCardHeader'

import DetailCardFoodContent from './DetailCardFoodContent'

import { Food, QuantifiedFood } from 'Models'

interface Props {
  food: Food
  quantifiedFood: QuantifiedFood
  isFavorite?: boolean
  onFavoriteChange?: (value: boolean) => void
  onClose?: () => void
  onChangePress?: () => void
  onSavePress?: () => void
}

export const DetailCardFood: FC<Props> = ({
  food,
  quantifiedFood,
  isFavorite,
  onFavoriteChange,
  onChangePress,
  onSavePress,
  onClose
}: Props) => {
  return (
    <div className="detailcard">
      <DetailCardHeader
        energy={(food.nutritions || {}).energy || 0}
        imageUrl={food.imageUrl}
        isFavorite={isFavorite}
        onFavoriteChange={onFavoriteChange}
        onClose={onClose}
        source={food.source}
        extension="pro 100g"
      />
      <DetailCardFoodContent
        food={food}
        quantifiedFood={quantifiedFood}
        onChangePress={onChangePress}
        onSavePress={onSavePress}
      />
    </div>
  )
}
