import React, { FC, ReactElement } from 'react'

import { ResponsiveCardBase } from 'Components'
import { DebugPanel } from 'Components/Debug'
import { ResponsiveCardBaseProps } from 'Components/ResponsiveCardBase/ResponsiveCardBase'
import { FavoritesMapping, Food } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAppSelector } from 'ReduxStore/hooks'
import { Formatter } from 'Utils'

export type CardFoodProps = Pick<ResponsiveCardBaseProps, 'onClick' | 'iconButtons' | 'cardButtons'> & {
  food: Food
  children: ReactElement
  toggleChildrenIsOn: boolean
}

export const CardFood: FC<CardFoodProps> = ({
  food,
  children,
  toggleChildrenIsOn,
  ...responsiveCardBaseProps
}: CardFoodProps) => {
  const { id, name, imageUrl, nutritions } = food
  const isFavorite = !!useAppSelector(favoriteSelector(FavoritesMapping.FOODS, food.id))

  return (
    <>
      <DebugPanel>
        <code>{JSON.stringify(food, null, 2)}</code>
      </DebugPanel>
      <ResponsiveCardBase
        key={id}
        title={name}
        isFavorite={isFavorite}
        details={Formatter.formatEnergy(nutritions && nutritions.energy)}
        className="is-eatable"
        imageUrl={imageUrl}
        toggleChildrenIsOn={toggleChildrenIsOn}
        source={food.source?.name}
        {...responsiveCardBaseProps}
      >
        {children}
      </ResponsiveCardBase>
    </>
  )
}
