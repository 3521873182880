import React, { FC, ReactElement } from 'react'

import { ResponsiveCardBase, buildEatableDetails } from 'Components'
import { DebugPanel } from 'Components/Debug'
import { ResponsiveCardBaseProps } from 'Components/ResponsiveCardBase/ResponsiveCardBase'
import { FavoritesMapping, Recipe } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAppSelector } from 'ReduxStore/hooks'

export type CardRecipeProps = Pick<ResponsiveCardBaseProps, 'onClick' | 'iconButtons' | 'cardButtons'> & {
  recipe: Recipe
  children?: ReactElement
  toggleChildrenIsOn?: boolean
}

export const CardRecipe: FC<CardRecipeProps> = ({
  recipe,
  children,
  toggleChildrenIsOn,
  ...responsiveCardBaseProps
}: CardRecipeProps) => {
  const { id, name, imageUrl } = recipe
  const isFavorite = !!useAppSelector(favoriteSelector(FavoritesMapping.RECIPES, recipe.id))
  const details = buildEatableDetails({ recipeId: recipe.id, recipe }, { quantity: 1, unit: 'PORTION' }, 'normal')
  return (
    <>
      <DebugPanel>
        <code>{JSON.stringify(recipe, null, 2)}</code>
      </DebugPanel>
      <ResponsiveCardBase
        key={id}
        title={name}
        isFavorite={isFavorite}
        source={recipe.source?.name}
        details={details}
        className="is-eatable"
        imageUrl={imageUrl}
        toggleChildrenIsOn={toggleChildrenIsOn}
        {...responsiveCardBaseProps}
      >
        {children}
      </ResponsiveCardBase>
    </>
  )
}
